import React from 'react';

import { Button } from '@chakra-ui/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { fireEvent, formatTestId, pathPrefix } from '@/util/helper';

const ButtonVariants = {
	one: undefined,
	two: 'outline',
	three: 'ghost',
};

export const SimpleTopMenuItem = React.memo((props) => {
	const navigate = useNavigate();
	const location = useLocation();

	const onClick = (e) => {
		e.preventDefault();

		const proceed = () => {
			const path = pathPrefix() + props.href || pathPrefix();

			if (props.href) navigate({ pathname: path, search: location.search });

			let closeDiv = document.getElementById('close-stuff');

			if (closeDiv) fireEvent(closeDiv, 'mousedown');

			if (props.onClick) {
				props.onClick(e);
			}

			e.target.blur();
		};

		proceed();
	};

	return (
		<Link
			to={{ pathname: pathPrefix() + (props.href ?? ''), search: location.search }}
			onClick={(e) => onClick(e)}
			data-testid={formatTestId(props.label)}
		>
			<Button variant={ButtonVariants[props.type]} {...props.buttonProps} size={props.size}>
				{props.label}
				{props.children}
			</Button>
		</Link>
	);
});

export default SimpleTopMenuItem;
