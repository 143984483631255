import React from 'react';

import { AllComponentTypes, getPadding, getShadowValFromProps } from 'shared/src/utils/shared.js';

import { getFormFieldFontStyles } from '@/util/helper';

export const basicLabelStyles = {
	userSelect: 'none',
	whiteSpace: 'pre-wrap',
	wordBreak: 'break-word',
};

export const inputGroupStyles = {
	padding: 0,
	display: 'flex',
	flexDirection: 'column',
};

export const SharedInput = (props) => {
	let inputRef = React.createRef();

	const {
		labelTextDecoration,
		labelFontStyle,
		labelFontWeight,
		labelHide,
		labelFont,
		labelFontSize,
		labelFontColour,
		labelPosition,
		labelWidth,
		fieldFontWeight,
		fieldFontStyle,
		fieldTextDecoration,
		fieldFont,
		fieldFontSize,
		fieldFontColour,
		fieldBorderWidth,
		fieldBorderColour,
		fieldBorderRadius,
		fieldPadding,
		fieldBgColor,
		// fieldWidth,
		asteriskColor,
		formId,
		placeholderFont,
		placeholderFontColour,
		placeholderFontWeight,
		placeholderTextDecoration,
		placeholderFontStyle,
		descriptionFontColour,
		descriptionFontSize,
		descriptionSpacing,
		descriptionFontFamily,
		descriptionTextDecoration,
		descriptionFontStyle,
		descriptionFontWeight,
		box_shadow,
		background_color,
		variant,
	} = props;

	const fieldStyle = {
		background: fieldBgColor,
		padding: fieldPadding,
		paddingLeft: props.type === AllComponentTypes.phone_input ? fieldPadding + 50 : fieldPadding,
		color: fieldFontColour,
		fontSize: fieldFontSize,
		borderWidth: fieldBorderWidth,
		borderColor: fieldBorderColour,
		borderStyle: 'solid',
		borderRadius: fieldBorderRadius,
		fontWeight: fieldFontWeight,
		fontStyle: fieldFontStyle,
		textDecoration: fieldTextDecoration,
		boxShadow: getShadowValFromProps(box_shadow),
		...getFormFieldFontStyles(fieldFont),
	};

	const labelDimension =
		labelPosition === 'row'
			? {
					width: labelWidth,
				}
			: {
					minHeight: labelWidth,
				};

	const labelStyle = {
		color: labelFontColour,
		fontSize: labelFontSize,
		fontWeight: labelFontWeight,
		fontStyle: labelFontStyle,
		textDecoration: labelTextDecoration,
		display: labelHide ? 'none' : '',
		...labelDimension,
		...getFormFieldFontStyles(labelFont),
	};

	const containerStyle = {
		flexDirection: labelPosition,
		// width: `${fieldWidth}%`,
		width: '100%',
	};

	const asteriskStyles = {
		color: asteriskColor,
		textDecoration: 'none',
		fontWeight: 'normal',
		fontStyle: 'normal',
		fontFamily: 'inherit',
	};

	const { flexDirection } = containerStyle;
	const dynamicContainerStyles =
		flexDirection === 'row'
			? {
					alignItems: 'center',
					WebkitBoxAlign: 'center',
					MsFlexAlign: 'center',
				}
			: {};

	const directionClass =
		flexDirection === 'row' ? 'lp-flex-direction-horizontal lp-flex-align-center' : 'lp-flex-direction-vertical element-vertical-ie';

	const hiddenStyles = props.hidden
		? {
				display: 'none',
			}
		: {};

	const descriptionStyles = {
		color: descriptionFontColour,
		fontSize: descriptionFontSize,
		fontFamily: descriptionFontFamily,
		marginTop: descriptionSpacing,
		fontStyle: descriptionFontStyle,
		fontWeight: descriptionFontWeight,
		textDecoration: descriptionTextDecoration,
		display: props.descriptionHide ? 'none' : 'block',
		paddingLeft: labelPosition === 'row' ? labelWidth : undefined,
	};

	React.useEffect(() => {
		if (props.type === AllComponentTypes.phone_input && window.intlTelInput) {
			window.intlTelInput(inputRef, {
				utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@21.0.1/build/js/utils.js',
				initialCountry: 'us',
				nationalMode: true,
				showFlags: false,
				showSelectedDialCode: true,
			});
		}
	}, []);

	const inputProps = {
		ref: (inputReference) => (inputRef = inputReference),
		className: 'lp-flex-1 form-input-element',
		'data-type': props.type,
		'data-label': props.label,
		'data-required': props.required,
		// data-validate:props.validate || false,
		type: props.inputType,
		placeholder: props.placeholder,
		disabled: props.disabled,
		style: { lineHeight: 'initial', width: '100%', minWidth: 0, outline: 'none', flex: 1, ...fieldStyle },
		name: props.name,
		value: props.value,
		rows: props.variant === 'textarea' ? '5' : undefined,
		readOnly: props.readOnly,
	};

	let inputElement = <input {...inputProps} />;

	if (props.variant === 'textarea') {
		inputElement = <textarea {...inputProps} rows={4} />;
	}

	return (
		<div
			id={`${props.type}-${props.id}`}
			style={{
				width: '100%',
				pointerEvents: 'none',
				position: 'relative',
				backgroundColor: background_color,
				...getPadding(props, true),
				paddingBottom: props.padding > 25 ? props.padding : 25,
				...hiddenStyles,
			}}
			className="form-input-wrapper lp-flex-1"
		>
			<label
				className={`form-input-wrapper-element lp-flex-container form-input-${formId} ${directionClass}`}
				style={{ ...inputGroupStyles, ...dynamicContainerStyles, ...containerStyle }}
			>
				<span style={{ ...basicLabelStyles, ...labelStyle }} className="form-field-label">
					{props.label}
					{props.required && (
						<span className="form-field-required" style={asteriskStyles}>
							{' '}
							*
						</span>
					)}
				</span>
				<div style={{ display: 'block', flex: '1 1 auto' }} className="lp-flex-1">
					{inputElement}
				</div>
			</label>
			{props.description && <div style={descriptionStyles}>{props.description}</div>}
			<style>
				{`#${props.type}-${props.id} ${variant}::placeholder {
					color: ${placeholderFontColour};
					font-size: ${fieldFontSize}px;
					font-weight: ${placeholderFontStyle};
					font-style: ${placeholderFontWeight};
					text-decoration: ${placeholderTextDecoration};
					font-family: ${placeholderFont};
				}`}
			</style>
		</div>
	);
};

SharedInput.defaultProps = {
	variant: 'input',
};

export default SharedInput;
