import React from 'react';

import { AllComponentTypes, getPadding, getTransformerFormFieldFontStyles } from 'shared/src/utils/shared.js';

import Checkbox, { generateCheckboxClass } from './Checkbox.react';

export const CheckboxGroup = (props) => {
	const { formId } = props;

	const { options } = props;

	const id = props.id ? props.id.toString().replace(/-/g, '') : '';

	if (options && options.length) {
		return (
			<div
				id={id}
				className={`component checkbox-group-field ${
					props.required ? 'required-component' : ''
				} checkbox-group-component checkbox-group${props.id.replace(/-/g, '')} form-input-wrapper`}
			>
				<div className={`form-input-wrapper-element lp-flex-container lp-flex-direction-vertical form-input-${formId}`}>
					<span className="global-input-label form-field-label form-group-label ">
						{props.label}
						<span className="global-field-required form-field-required "> *</span>
					</span>
					<div className="checkbox-group-container" data-options-limit={props.optionsLimit}>
						{options.map((item, index) => {
							return (
								<div className="checkbox-wrapper" key={item.value}>
									<Checkbox
										{...props}
										isCheckboxGroup
										id={`${id}${index}`}
										labelHide={false}
										type={AllComponentTypes.checkbox}
										value={item.value}
										label={item.label}
										descriptionHide
										required={false}
										padding={0}
										multiPadding={{
											allow: false,
											paddingTop: 0,
											paddingRight: 0,
											paddingBottom: 0,
											paddingLeft: 0,
										}}
									/>
								</div>
							);
						})}
						<input
							data-id={id}
							data-label={props.label}
							className="checkbox-group-input"
							data-required={props.required}
							required={props.required}
							// type="hidden"
							name={props.name}
							data-type={props.type}
							style={{ display: 'none' }}
						/>
					</div>
					{props.description && <div className="global-input-description form-input-description  ">{props.description}</div>}
				</div>
			</div>
		);
	}

	return null;
};

export const generateCheckboxGroupClass = (component) => {
	const checkboxClasses =
		component.options && component.options.length
			? component.options
					.map((item, i) =>
						generateCheckboxClass({
							...component,
							labelHide: false,
							type: AllComponentTypes.checkbox,
							descriptionHide: true,
							id: `${component.id}${i}`,
							padding: 0,
							multiPadding: {
								allow: false,
								paddingTop: 0,
								paddingRight: 0,
								paddingBottom: 0,
								paddingLeft: 0,
							},
						}),
					)
					.join(' ')
			: '';

	return `
        .checkbox-group${component.id.replace(/-/g, '')} {
            width: 100%; 
            background-color: ${component.background_color};
            display: ${component.hidden ? 'none' : 'block'};
			${getPadding(component)}
        }

        .checkbox-group${component.id.replace(/-/g, '')} .form-field-label {
            color: ${component.labelFontColour};
            font-size: ${component.labelFontSize}px;
            font-weight: ${component.labelFontWeight};
            font-style: ${component.labelFontStyle};
            text-decoration: ${component.labelTextDecoration};
            display: ${component.labelHide ? 'none' : 'block'};
            ${getTransformerFormFieldFontStyles(component.labelFont)}
        }

		.checkbox-group${component.id.replace(/-/g, '')} .form-group-label {
			min-height: ${component.labelWidth}px;
        }

        .checkbox-group${component.id.replace(/-/g, '')} .form-field-label .form-field-required {
            color: ${component.asteriskColor};
        }
				
        .checkbox-group${component.id.replace(/-/g, '')} .form-input-description, .checkbox-group${component.id.replace(
					/-/g,
					'',
				)} .form-input-error-message {
            font-style: ${component.descriptionFontStyle};
			font-weight: ${component.descriptionFontWeight};
			text-decoration: ${component.descriptionTextDecoration};
            color: ${component.descriptionFontColour};
            font-family: ${component.descriptionFontFamily};
            font-size: ${component.descriptionFontSize}px;
            margin-top: ${component.descriptionSpacing}px;
            display: ${component.descriptionHide ? 'none' : 'block'};
        }

		.checkbox-group${component.id.replace(/-/g, '')} .checkbox-wrapper:not(:last-of-type) {
			margin-bottom: ${component.optionsSpacing}px;
		}

		${checkboxClasses}
    `;
};

export default CheckboxGroup;
