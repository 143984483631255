import React from 'react';

import { Box, Text } from '@chakra-ui/react';
import { mdiCheckCircleOutline } from '@mdi/js';
import { merge } from 'lodash';
import { FieldComponentGroups } from 'shared/src/utils/shared.js';

import useContentStore from '@/stores/ContentStore';
import useTextEditorStore from '@/stores/TextEditorStore';

import { compareFontLabels } from '@/util/helper';
import { FontFamilyOptions, FormFieldTypes, FormLabelPositions } from '@/util/resources';

import { SettingsBox } from '@/components/gui/settingsContext/SettingsContext';
import ButtonSetting from '@/components/gui/shared/settings/ButtonSetting.react';
import CheckBoxSetting from '@/components/gui/shared/settings/CheckBoxSetting.react';
import ColorPickerSetting from '@/components/gui/shared/settings/ColorPickerSetting.react';
import DropDownSetting from '@/components/gui/shared/settings/DropDownSetting.react';
import FormInputOptions from '@/components/gui/shared/settings/FormInputOptions.react';
import MultiSetting from '@/components/gui/shared/settings/MultiSetting.react';
import NumberSetting from '@/components/gui/shared/settings/NumberSetting.react';
import PaddingSetting from '@/components/gui/shared/settings/PaddingSetting.react';
import { settingsLabelStyle } from '@/components/gui/shared/settings/Settings.styles';
import TextInputSetting from '@/components/gui/shared/settings/TextInputSetting.react';
import ToggleBlock from '@/components/gui/shared/settings/ToggleBlock.react';
import ToggleButtonSetting from '@/components/gui/shared/settings/ToggleButtonSetting.react';

export const SharedInputSettingsFields = {
	label: 'label',
	value: 'value',
	description: 'description',
	name: 'name',
	placeholder: 'placeholder',
	prefill: 'prefill',
	hiddenField: 'hiddenField',
	// labelTextDecoration: 'labelTextDecoration',
	// labelFontStyle: 'labelFontStyle',
	// labelFontWeight: 'labelFontWeight',
	labelHide: 'labelHide',
	labelFont: 'labelFont',
	labelFontSize: 'labelFontSize',
	labelFontColour: 'labelFontColour',
	labelPosition: 'labelPosition',
	labelWidth: 'labelWidth',
	asteriskColor: 'asteriskColor',
	required: 'required',
	fieldFontWeight: 'fieldFontWeight',
	fieldFontStyle: 'fieldFontStyle',
	fieldTextDecoration: 'fieldTextDecoration',
	fieldFont: 'fieldFont',
	fieldFontSize: 'fieldFontSize',
	fieldFontColour: 'fieldFontColour',
	fieldBorderWidth: 'fieldBorderWidth',
	fieldBorderColour: 'fieldBorderColour',
	fieldBorderRadius: 'fieldBorderRadius',
	fieldPadding: 'fieldPadding',
	fieldBgColor: 'fieldBgColor',
	placeholderFont: 'placeholderFont',
	placeholderFontSize: 'placeholderFontSize',
	placeholderFontColour: 'placeholderFontColour',
	placeholderFontWeight: 'placeholderFontWeight',
	placeholderTextDecoration: 'placeholderTextDecoration',
	placeholderFontStyle: 'placeholderFontStyle',
	descriptionFontColour: 'descriptionFontColour',
	descriptionFontSize: 'descriptionFontSize',
	descriptionFontFamily: 'descriptionFontFamily',
	descriptionSpacing: 'descriptionSpacing',
	descriptionHide: 'descriptionHide',
	descriptionTextDecoration: 'descriptionTextDecoration',
	options: 'options',
	optionsSpacing: 'optionsSpacing',
	optionsLimit: 'optionsLimit',
	padding: 'padding',
	pattern: 'pattern',
	maxLength: 'maxLength',
};

const prefillInputType = {
	email_input: 'email',
	phone_input: 'tel',
	text_input: 'text',
	longtext_input: 'text',
	date_input: 'date',
	number_input: 'number',
};

export const defaultFormInputSettings = {
	name: {
		active: true,
		label: 'Name',
	},
	label: {
		active: true,
		label: 'Label',
	},
	placeholder: {
		active: true,
		label: 'Placeholder',
	},
	prefill: {
		active: true,
		label: 'Prefill value',
	},
	hiddenField: {
		active: true,
		label: 'Hidden',
	},
	description: {
		active: true,
		label: 'Help text',
	},
	required: {
		active: true,
		label: 'Required',
	},
	pattern: {
		active: false,
		label: 'Pattern',
	},
	options: {
		active: false,
		label: 'Options',
	},
	optionsSpacing: {
		active: false,
		label: 'Options spacing',
	},
	optionsLimit: {
		active: false,
		label: 'Number of options',
	},
	padding: {
		active: true,
		label: 'Padding',
	},
	maxLength: {
		active: false,
		label: 'Character limit',
	},
	fieldGroup: {
		active: true,
		label: 'Field styles',
		settings: {
			fieldBgColor: {
				active: true,
				label: 'Field background color',
			},
			fieldBorderWidth: {
				active: true,
				label: 'Border width',
			},
			fieldBorderRadius: {
				active: true,
				label: 'Border radius',
			},
			fieldBorderColour: {
				active: true,
				label: 'Border color',
			},
			fieldFont: {
				active: true,
				label: 'Field font',
			},
			fieldFontSize: {
				active: true,
				label: 'Field font size',
			},
			fieldPadding: {
				active: true,
				label: 'Inner padding',
			},
			fieldFontColour: {
				active: true,
				label: 'Font color',
			},
			fieldTextDecoration: {
				active: true,
				label: 'Field decorations',
			},
		},
	},
	labelGroup: {
		active: true,
		label: 'Label styles',
		settings: {
			labelHide: {
				active: true,
				label: 'Hide label',
			},
			labelFont: {
				active: true,
				label: 'Label font',
			},
			labelPosition: {
				active: true,
				label: 'Label position',
			},
			labelFontSize: {
				active: true,
				label: 'Label font size',
			},
			labelWidth: {
				active: true,
				label: 'Label width',
			},
			labelFontColour: {
				active: true,
				label: 'Label font color',
			},
			labelTextDecoration: {
				active: true,
				label: 'Label decoration',
			},
			asteriskColor: {
				active: true,
				label: 'Asterisk color',
			},
		},
	},
	placeholderGroup: {
		active: true,
		label: 'Placeholder styles',
		settings: {
			placeholderFont: {
				active: true,
				label: 'Placeholder font',
			},
			placeholderFontSize: {
				active: true,
				label: 'Placeholder font size',
			},
			placeholderFontColour: {
				active: true,
				label: 'Placeholder font color',
			},
		},
	},
	descriptionGroup: {
		active: true,
		label: 'Help text',
		settings: {
			descriptionHide: {
				active: true,
				label: 'Hide help text',
			},
			descriptionFontFamily: {
				active: true,
				label: 'Help text font',
			},
			descriptionFontColour: {
				active: true,
				label: 'Help text color',
			},
			descriptionFontSize: {
				active: true,
				label: 'Help text font size',
			},
			descriptionSpacing: {
				active: true,
				label: 'Help text top spacing',
			},
			descriptionTextDecoration: {
				active: true,
				label: 'Text decoration',
			},
		},
	},
};

export const SharedInputSettingComponents = React.memo((props) => {
	const { leftSidebarOptions, onChange } = props;
	const allFontOptions = FontFamilyOptions.concat(useTextEditorStore.getState().custom_fonts).sort(compareFontLabels);

	const group = React.useMemo(
		() =>
			Object.keys(FieldComponentGroups).find((item) => {
				if (FieldComponentGroups[item].includes(props.type)) {
					return item;
				}

				return null;
			}),
		[props.type],
	);

	const settings = React.useMemo(() => {
		return merge({}, defaultFormInputSettings, props.settings);
	}, []);

	const onFontSizeBlur = (data) => {
		if (data.type === SharedInputSettingsFields.fieldFontSize && data.value < props.placeholderFontSize) {
			const newData = {
				type: SharedInputSettingsFields.placeholderFontSize,
				value: data.value,
			};

			onChange(newData);
		}
	};

	const applyLabelSettingsToAllFormFields = () => {
		const settings = {
			labelHide: props.labelHide,
			labelFont: props.labelFont,
			labelFontSize: props.labelFontSize,
			labelFontColour: props.labelFontColour,
			labelPosition: props.labelPosition,
			labelSpacing: props.labelSpacing,
			labelFontStyle: props.labelFontStyle,
			labelFontWeight: props.labelFontWeight,
			labelWidth: props.labelWidth,
			asteriskColor: props.asteriskColor,
			labelTextDecoration: props.labelTextDecoration,
		};

		if (group) useContentStore.getState().saveForAllFormElementSettings(settings, group);
		useContentStore.getState().saveSummaryPageSettings(settings, 'fieldStyles');
	};

	const applyFieldSettingsToAllFormFields = () => {
		const settings = {
			fieldFontWeight: props.fieldFontWeight,
			fieldFontStyle: props.fieldFontStyle,
			fieldTextDecoration: props.fieldTextDecoration,
			fieldFont: props.fieldFont,
			fieldFontSize: props.fieldFontSize,
			fieldFontColour: props.fieldFontColour,
			fieldBorderWidth: props.fieldBorderWidth,
			fieldBorderColour: props.fieldBorderColour,
			fieldBorderRadius: props.fieldBorderRadius,
			fieldPadding: props.fieldPadding,
			fieldBgColor: props.fieldBgColor,
		};

		if (group) useContentStore.getState().saveForAllFormElementSettings(settings, group);
		useContentStore.getState().saveSummaryPageSettings(settings, 'fieldStyles');
	};

	const applyPlaceholderSettingsToAllFormFields = () => {
		const settings = {
			placeholderFont: props.placeholderFont,
			placeholderFontSize: props.placeholderFontSize,
			placeholderFontColour: props.placeholderFontColour,
			placeholderFontWeight: props.placeholderFontWeight,
			placeholderTextDecoration: props.placeholderTextDecoration,
			placeholderFontStyle: props.placeholderFontStyle,
		};

		if (group) useContentStore.getState().saveForAllFormElementSettings(settings, group);
	};

	const applyDescriptionSettingsToAllFormFields = () => {
		const settings = {
			descriptionFontColour: props.descriptionFontColour,
			descriptionFontSize: props.descriptionFontSize,
			descriptionFontFamily: props.descriptionFontFamily,
			descriptionSpacing: props.descriptionSpacing,
			descriptionHide: props.descriptionHide,
			descriptionFontStyle: props.descriptionFontStyle,
			descriptionFontWeight: props.descriptionFontWeight,
			descriptionTextDecoration: props.descriptionTextDecoration,
		};

		if (group) useContentStore.getState().saveForAllFormElementSettings(settings, group);
	};

	const onLabelPositionChange = (data) => {
		let settings = {};
		if (data.value === 'column' && props.labelWidth > 40) {
			settings = {
				[data.type]: data.value,
				[SharedInputSettingsFields.labelWidth]: 25,
			};
		} else if (data.value === 'row' && props.labelWidth < 120) {
			settings = {
				[data.type]: data.value,
				[SharedInputSettingsFields.labelWidth]: 120,
			};
		}
		if (props.address) {
			useContentStore.getState().saveElementSettings({
				address: props.address,
				settings,
			});
		}
	};

	const getDecorationSettings = (data) => {
		let settings = {};

		if (data.type === 'italics') {
			if (data.fontType) {
				return onChange && onChange({ type: `${data.fontType}FontStyle`, value: (data.value && 'italic') || 'normal' });
			} else {
				settings.fontStyle = (data.value && 'italic') || 'normal';
			}
		} else if (data.type === 'bold') {
			if (data.fontType) {
				return onChange && onChange({ type: `${data.fontType}FontWeight`, value: (data.value && 'bold') || 'normal' });
			} else {
				settings.fontWeight = (data.value && 'bold') || 'normal';
			}
		} else if (data.type === 'underline') {
			if (data.fontType) {
				return (
					onChange &&
					onChange({
						type: `${data.fontType}TextDecoration`,
						value: (data.value && 'underline') || 'none',
					})
				);
			} else {
				settings.textDecoration = (data.value && 'underline') || 'none';
			}
		}

		return settings;
	};

	return (
		<>
			{!props.isMobileView && (
				<SettingsBox blockId={props.blockId}>
					{settings.name.active && (
						<TextInputSetting
							type={SharedInputSettingsFields.name}
							onChange={onChange}
							text={props.name || ''}
							label={settings.name.label}
							ariaLabel="Enter field name"
							disableEmoji
							tooltip={`The field “Name“ should be unique.`}
						/>
					)}
					{settings.label.active && (
						<TextInputSetting
							type={SharedInputSettingsFields.label}
							onChange={onChange}
							text={props.label || ''}
							label={settings.label.label}
							ariaLabel="Enter label"
						/>
					)}
					{settings.placeholder.active && (
						<TextInputSetting
							type={SharedInputSettingsFields.placeholder}
							onChange={onChange}
							text={props.placeholder || ''}
							label={settings.placeholder.label}
							ariaLabel="Enter placeholder text"
						/>
					)}
					{settings.description.active && (
						<TextInputSetting
							type={SharedInputSettingsFields.description}
							onChange={onChange}
							text={props.description || ''}
							label={settings.description.label}
							ariaLabel="Enter help text"
						/>
					)}
					{settings.prefill.active && (
						<TextInputSetting
							type={SharedInputSettingsFields.prefill}
							onChange={onChange}
							text={props.prefill || ''}
							label={settings.prefill.label}
							ariaLabel="Enter prefill value"
							inputType={prefillInputType[props.type]}
						/>
					)}
					{settings.required.active && (
						<Box px={2} py={3} mb={5} borderRadius="md" borderStyle="solid" borderWidth={1} borderColor="neutral.300">
							<CheckBoxSetting
								onClick={onChange}
								label={settings.required.label}
								checked={props.required}
								type={SharedInputSettingsFields.required}
								styleProps={{ mb: 0 }}
							/>
						</Box>
					)}
					{settings.hiddenField.active && (
						<Box px={2} py={3} mb={5} borderRadius="md" borderStyle="solid" borderWidth={1} borderColor="neutral.300">
							<CheckBoxSetting
								onClick={onChange}
								label={settings.hiddenField.label}
								checked={props.hiddenField}
								type={SharedInputSettingsFields.hiddenField}
								disabled={props.required}
								styleProps={{ mb: 0 }}
							/>
							<Text variant="small">Required fields can't be hidden</Text>
						</Box>
					)}
					{settings.pattern.active && (
						<TextInputSetting
							type={SharedInputSettingsFields.pattern}
							onChange={onChange}
							text={props.pattern || ''}
							label={settings.pattern.label}
							ariaLabel="Enter a Regex to validate the field"
							tooltip="Pattern field (regular expression) is a custom validation rule using patterns. Example: Ensure email format by using '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$' to match valid emails."
						/>
					)}
					{settings.maxLength.active && (
						<NumberSetting
							type={SharedInputSettingsFields.maxLength}
							onChange={onChange}
							text={props.maxLength || ''}
							label={settings.maxLength.label}
							ariaLabel="Character limit for the field"
						/>
					)}
					{settings.padding.active && (
						<PaddingSetting
							multiPadding={props.multiPadding}
							padding={props.padding}
							type="multiPadding"
							label={settings.padding.label}
							secondaryType={SharedInputSettingsFields.padding}
							onChange={onChange}
						/>
					)}
					{settings.options.active && (
						<FormInputOptions
							type={SharedInputSettingsFields.options}
							onChange={onChange}
							label={settings.options.label}
							options={props.options}
							hasCustomValues={props.hasCustomValues}
						/>
					)}
					{settings.optionsSpacing.active && (
						<NumberSetting
							type={SharedInputSettingsFields.optionsSpacing}
							onChange={onChange}
							text={props.optionsSpacing}
							label={settings.optionsSpacing.label}
							ariaLabel="Options spacing"
						/>
					)}
					{settings.optionsLimit.active && (
						<NumberSetting
							type={SharedInputSettingsFields.optionsLimit}
							onChange={onChange}
							text={props.optionsLimit || ''}
							label={settings.optionsLimit.label}
							ariaLabel="Number of options"
						/>
					)}
				</SettingsBox>
			)}
			{settings.fieldGroup.active && (
				<ToggleBlock
					label={settings.fieldGroup.label}
					open={leftSidebarOptions && leftSidebarOptions.scrollTop > 0 ? leftSidebarOptions.scrollTop === FormFieldTypes.field : undefined}
					filterImmunity
				>
					{settings.fieldGroup.settings.fieldBgColor.active && (
						<ColorPickerSetting
							type={SharedInputSettingsFields.fieldBgColor}
							onChange={onChange}
							selectedColor={props.fieldBgColor}
							label={settings.fieldGroup.settings.fieldBgColor.label}
						/>
					)}
					<MultiSetting label="Field border width & border radius">
						{settings.fieldGroup.settings.fieldBorderWidth.active && (
							<NumberSetting
								type={SharedInputSettingsFields.fieldBorderWidth}
								onChange={onChange}
								text={props.fieldBorderWidth}
								label={settings.fieldGroup.settings.fieldBorderWidth.label}
								ariaLabel="Border width in pixels"
							/>
						)}
						{settings.fieldGroup.settings.fieldBorderRadius && (
							<NumberSetting
								type={SharedInputSettingsFields.fieldBorderRadius}
								onChange={onChange}
								text={props.fieldBorderRadius}
								label={settings.fieldGroup.settings.fieldBorderRadius.label}
								ariaLabel="Field radius in pixels"
							/>
						)}
					</MultiSetting>
					{settings.fieldGroup.settings.fieldBorderColour.active && (
						<ColorPickerSetting
							type={SharedInputSettingsFields.fieldBorderColour}
							onChange={onChange}
							selectedColor={props.fieldBorderColour}
							label={settings.fieldGroup.settings.fieldBorderColour.label}
						/>
					)}
					{settings.fieldGroup.settings.fieldFont.active && (
						<DropDownSetting
							type={SharedInputSettingsFields.fieldFont}
							onChange={onChange}
							selected={props.fieldFont}
							label={settings.fieldGroup.settings.fieldFont.label}
							options={allFontOptions}
						/>
					)}
					<MultiSetting label="Field font size & inner padding">
						{settings.fieldGroup.settings.fieldFontSize.active && (
							<NumberSetting
								type={SharedInputSettingsFields.fieldFontSize}
								onChange={onChange}
								text={props.fieldFontSize}
								label={settings.fieldGroup.settings.fieldFontSize.label}
								ariaLabel="Font size in pixels"
								onBlur={onFontSizeBlur}
							/>
						)}
						{settings.fieldGroup.settings.fieldPadding.active && (
							<NumberSetting
								type={SharedInputSettingsFields.fieldPadding}
								onChange={onChange}
								text={props.fieldPadding}
								label={settings.fieldGroup.settings.fieldPadding.label}
								ariaLabel="Inner padding in pixels"
							/>
						)}
					</MultiSetting>
					{settings.fieldGroup.settings.fieldFontColour.active && (
						<ColorPickerSetting
							type={SharedInputSettingsFields.fieldFontColour}
							onChange={onChange}
							selectedColor={props.fieldFontColour}
							label={settings.fieldGroup.settings.fieldFontColour.label}
						/>
					)}
					{settings.fieldGroup.settings.fieldTextDecoration.active && (
						<DecorationSettings
							type="field"
							label={settings.fieldGroup.settings.fieldTextDecoration.label}
							onDecorationsChange={getDecorationSettings}
							textDecoration={props.fieldTextDecoration}
							fontStyle={props.fieldFontStyle}
							fontWeight={props.fieldFontWeight}
						/>
					)}
					<ButtonSetting
						onClick={applyFieldSettingsToAllFormFields}
						tooltip="Apply to all form elements"
						label="Apply to all"
						icon={mdiCheckCircleOutline}
						testId="check_circle"
						customStyle={{ mb: 5, mt: 5 }}
					/>
				</ToggleBlock>
			)}
			{settings.labelGroup.active && (
				<ToggleBlock
					label={settings.labelGroup.label}
					open={leftSidebarOptions && leftSidebarOptions.scrollTop > 0 ? leftSidebarOptions.scrollTop === FormFieldTypes.label : undefined}
					filterImmunity
				>
					{settings.labelGroup.settings.labelHide.active && (
						<CheckBoxSetting
							onClick={onChange}
							label={settings.labelGroup.settings.labelHide.label}
							checked={props.labelHide}
							type={SharedInputSettingsFields.labelHide}
						/>
					)}
					<MultiSetting label="Label font & select label position">
						{settings.labelGroup.settings.labelFont.active && (
							<DropDownSetting
								type={SharedInputSettingsFields.labelFont}
								onChange={onChange}
								selected={props.labelFont}
								label={settings.labelGroup.settings.labelFont.label}
								options={allFontOptions}
							/>
						)}
						{settings.labelGroup.settings.labelPosition.active && (
							<DropDownSetting
								type={SharedInputSettingsFields.labelPosition}
								onChange={onLabelPositionChange}
								selected={props.labelPosition}
								label={settings.labelGroup.settings.labelPosition.label}
								options={FormLabelPositions}
							/>
						)}
					</MultiSetting>
					<MultiSetting label="Label width & font size">
						{settings.labelGroup.settings.labelFontSize.active && (
							<NumberSetting
								type={SharedInputSettingsFields.labelFontSize}
								onChange={onChange}
								text={props.labelFontSize}
								label={settings.labelGroup.settings.labelFontSize.label}
								ariaLabel="Font size in pixels"
							/>
						)}
						{settings.labelGroup.settings.labelWidth.active && (
							<NumberSetting
								type={SharedInputSettingsFields.labelWidth}
								onChange={onChange}
								text={props.labelWidth}
								label={settings.labelGroup.settings.labelWidth.label}
								ariaLabel="Label width in pixels"
							/>
						)}
					</MultiSetting>
					{settings.labelGroup.settings.labelFontColour.active && (
						<ColorPickerSetting
							type={SharedInputSettingsFields.labelFontColour}
							onChange={onChange}
							selectedColor={props.labelFontColour}
							label={settings.labelGroup.settings.labelFontColour.label}
						/>
					)}
					{settings.labelGroup.settings.asteriskColor.active && (
						<ColorPickerSetting
							type={SharedInputSettingsFields.asteriskColor}
							onChange={onChange}
							selectedColor={props.asteriskColor}
							label={settings.labelGroup.settings.asteriskColor.label}
						/>
					)}
					{settings.labelGroup.settings.labelTextDecoration.active && (
						<DecorationSettings
							type="label"
							label={settings.labelGroup.settings.labelTextDecoration.label}
							onDecorationsChange={getDecorationSettings}
							textDecoration={props.labelTextDecoration}
							fontStyle={props.labelFontStyle}
							fontWeight={props.labelFontWeight}
						/>
					)}
					<ButtonSetting
						onClick={applyLabelSettingsToAllFormFields}
						tooltip="Apply to all form elements"
						label="Apply to all"
						icon={mdiCheckCircleOutline}
						testId="check_circle"
						customStyle={{ mb: 5, mt: 5 }}
					/>
				</ToggleBlock>
			)}
			{settings.placeholderGroup.active && (
				<ToggleBlock
					label={settings.placeholderGroup.label}
					open={
						leftSidebarOptions && leftSidebarOptions.scrollTop > 0 ? leftSidebarOptions.scrollTop === FormFieldTypes.placeholder : undefined
					}
					filterImmunity
				>
					{settings.placeholderGroup.settings.placeholderFont.active && (
						<DropDownSetting
							type={SharedInputSettingsFields.placeholderFont}
							onChange={onChange}
							selected={props.placeholderFont}
							label={settings.placeholderGroup.settings.placeholderFont.label}
							options={allFontOptions}
						/>
					)}
					{settings.placeholderGroup.settings.placeholderFontSize.active && (
						<NumberSetting
							type={SharedInputSettingsFields.placeholderFontSize}
							onChange={onChange}
							text={props.fieldFontSize}
							label="Placeholder font size"
							max={props.fieldFontSize}
							ariaLabel="Font size in pixels"
							disabled
						/>
					)}
					{settings.placeholderGroup.settings.placeholderFontColour.active && (
						<ColorPickerSetting
							type={SharedInputSettingsFields.placeholderFontColour}
							onChange={onChange}
							selectedColor={props.placeholderFontColour}
							label={settings.placeholderGroup.settings.placeholderFontColour.label}
						/>
					)}
					<ButtonSetting
						onClick={applyPlaceholderSettingsToAllFormFields}
						tooltip="Apply to all form elements"
						label="Apply to all"
						icon={mdiCheckCircleOutline}
						testId="check_circle"
						customStyle={{ mb: 5, mt: 5 }}
					/>
				</ToggleBlock>
			)}
			{settings.descriptionGroup.active && (
				<ToggleBlock
					label={settings.descriptionGroup.label}
					open={
						leftSidebarOptions && leftSidebarOptions.scrollTop > 0 ? leftSidebarOptions.scrollTop === FormFieldTypes.placeholder : undefined
					}
					filterImmunity
				>
					{settings.descriptionGroup.settings.descriptionHide.active && (
						<CheckBoxSetting
							onClick={onChange}
							label={settings.descriptionGroup.settings.descriptionHide.label}
							checked={props.descriptionHide}
							type={SharedInputSettingsFields.descriptionHide}
						/>
					)}
					{settings.descriptionGroup.settings.descriptionFontFamily.active && (
						<DropDownSetting
							type={SharedInputSettingsFields.descriptionFontFamily}
							onChange={onChange}
							selected={props.descriptionFontFamily}
							label={settings.descriptionGroup.settings.descriptionFontFamily.label}
							options={allFontOptions}
						/>
					)}
					{settings.descriptionGroup.settings.descriptionFontColour.active && (
						<ColorPickerSetting
							type={SharedInputSettingsFields.descriptionFontColour}
							onChange={onChange}
							selectedColor={props.descriptionFontColour}
							label={settings.descriptionGroup.settings.descriptionFontColour.label}
						/>
					)}
					{settings.descriptionGroup.settings.descriptionFontSize.active && (
						<NumberSetting
							type={SharedInputSettingsFields.descriptionFontSize}
							onChange={onChange}
							text={props.descriptionFontSize}
							label={settings.descriptionGroup.settings.descriptionFontSize.label}
							ariaLabel="Font size in pixels"
						/>
					)}
					{settings.descriptionGroup.settings.descriptionSpacing.active && (
						<NumberSetting
							type={SharedInputSettingsFields.descriptionSpacing}
							onChange={onChange}
							text={props.descriptionSpacing}
							label={settings.descriptionGroup.settings.descriptionSpacing.label}
							ariaLabel="Spacing in pixels"
						/>
					)}
					{settings.descriptionGroup.settings.descriptionTextDecoration.active && (
						<DecorationSettings
							type="description"
							label={settings.descriptionGroup.settings.descriptionTextDecoration.label}
							onDecorationsChange={getDecorationSettings}
							textDecoration={props.descriptionTextDecoration}
							fontStyle={props.descriptionFontStyle}
							fontWeight={props.descriptionFontWeight}
						/>
					)}
					<ButtonSetting
						onClick={applyDescriptionSettingsToAllFormFields}
						tooltip="Apply to all form elements"
						label="Apply to all"
						icon={mdiCheckCircleOutline}
						testId="check_circle"
						customStyle={{ mb: 5, mt: 5 }}
					/>
				</ToggleBlock>
			)}
		</>
	);
});

export const DecorationSettings = React.memo((props) => {
	const onDecorationsChange = React.useCallback(
		(data) => {
			if (props.onDecorationsChange) props.onDecorationsChange({ ...data, fontType: props.type });
		},
		[props.type, props.onDecorationsChange],
	);

	return (
		<Box mb={5}>
			<Text {...settingsLabelStyle}>{props.label}</Text>
			<Box display="flex">
				<ToggleButtonSetting type="italics" onChange={onDecorationsChange} on={props.fontStyle === 'italic'} margin="0 20px 0 0" />
				<ToggleButtonSetting
					type="bold"
					onChange={onDecorationsChange}
					on={props.fontWeight === 'bold' || parseInt(props.fontWeight) >= 500}
					margin="0 20px 0 0"
				/>
				<ToggleButtonSetting
					type="underline"
					onChange={onDecorationsChange}
					on={props.textDecoration === 'underline'}
					margin="0 20px 0 0"
				/>
			</Box>
		</Box>
	);
});

const SharedInputSettings = (props) => {
	const saveElementSettings = useContentStore((state) => state.saveElementSettings);

	const onChange = (data) => {
		let settings = {};

		if (Array.isArray(data)) {
			data.forEach((setting) => {
				settings = {
					...settings,
					[setting.type]: setting.value,
				};
			});
		} else {
			settings = {
				[data.type]: data.value,
			};
		}

		if (props.address) {
			saveElementSettings({
				address: props.address,
				settings,
			});
		}
	};

	return (
		<Box className="input-settings">
			<SharedInputSettingComponents {...props} onChange={onChange} />
		</Box>
	);
};

export default SharedInputSettings;
